/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.scss';
// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
import $ from 'jquery';

import '../js/profil.js';
import '../js/eye_password.js';
import '../js/user_validate.js';
import '../js/form.js';
// import '../js/autocomplete.js';


// $( document ).ready(function() {
//     $('.collapse').collapse();
//     console.log('collapse');
// });


// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');
 require('jquery-ui/ui/widgets/datepicker');