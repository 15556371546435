// $(document).ready(function() {

//      console.log('sdfsdf');


//      $('#eye-password').click(function(){
//           $('#register_create_password_plainPassword_first').attr('type','text');
//      });


// });

