
$('.btnModifierProfil').click((e) =>{
    e.preventDefault()
    $('.btnModifierinfos').removeClass('d-none');
    $('.btnModifierProfil').addClass('d-none');
    $('.profil-password').removeClass('profil-password-visibility');
    });

    // $('.eye-password').click((e) =>{
    //     e.preventDefault()


    // }

$('#form-send-mail').click((e) =>{
    e.preventDefault()
    console.log('ok');
    var path = "{{ path('email') }}";
    var dataId = $('#form-send-mail').attr('data-id');
    console.log(dataId);
     $.ajax({
         type:"GET",
        //  url: path,
         url: "/email",
         data: dataId,
         dataType: 'html',
         success:function(code_html, statut){

             return statut;

         },
     });
     });


//recherche avancée
        $(window).on('load', function() {
            if($('#advanced_search_filterTwo option:selected').val()){
                $('.conditionTwo').removeClass('d-none');
            }
            if($('#advanced_search_filterThree option:selected').val()){
                $('.conditionThree').removeClass('d-none');
            }
            if($('#advanced_search_filterFour option:selected').val()){
                $('.conditionFour').removeClass('d-none');
            }
            if($('#advanced_search_filterFive option:selected').val()){
                $('.conditionFive').removeClass('d-none');
            }

        });

     $('.addCondition').click((e) =>{
        e.preventDefault()
        $('.conditionTwo').removeClass('d-none');

        if($('#advanced_search_filterTwo option:selected').val()){
            $('.conditionThree').removeClass('d-none');
        }
        if($('#advanced_search_filterThree option:selected').val()){
            $('.conditionFour').removeClass('d-none');
        }
        if($('#advanced_search_filterFour option:selected').val()){
            $('.conditionFive').removeClass('d-none');
        }

    });

    $('.deleteConditionTwo').click(function() {
        $('#advanced_search_filterTwo option:selected').remove().end();
      });
    $('.deleteConditionOne').click(function() {
        $('#advanced_search_filter option:selected').remove().end();
        $('#advanced_search_correspondance option:selected').remove().end();
    });
    $('.deleteConditionThree').click(function() {
        $('#advanced_search_filterThree option:selected').remove().end();
    });
    $('.deleteConditionFour').click(function() {
        $('#advanced_search_filterFour option:selected').remove().end();
    });
    $('.deleteConditionFive').click(function() {
        $('#advanced_search_filterFive option:selected').remove().end();
    });

//recherche avancée
