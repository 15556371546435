//   $('.user-validate').click((e) =>{
//       e.preventDefault()
//       let target = e.target
//       if(target.classList.contains('user-valide')) {
//           $(target).removeClass( 'user-valide' );
//           $(target).addClass( 'user-no-valide' );
//      }else {
//           $(target).removeClass( 'user-no-valide' );
//           $(target).addClass( 'user-valide' );
//       }
//       });

// $('.user-validate').click((e) =>{
//     e.preventDefault()
//     let target = e.target

//      if(target.contains('/images/valid.svg')){
//         $(target).attr('src','/images/close.svg');

//      }else{
//         $(target).attr('src','/images/valid.svg');
//      }

// });

$(document).ready(function() {
    $('[data-toggle="popover"]').popover();
});
$(function () {
    $('[data-toggle="tooltip"]').tooltip()
})


// $('.user-validate').click((e) =>{
//        e.preventDefault()
//        let target = e.target
//        if(target.classList.contains('user-valide')) {
//            $(target).removeClass( 'user-valide' );
//            $(target).addClass( 'user-no-valide' );
//       }else {
//            $(target).removeClass( 'user-no-valide' );
//            $(target).addClass( 'user-valide' );
//        }
// });




//   $('.user-validate').click((e) =>{
//       e.preventDefault()
//       var dataId = $('.user-validate').attr('data-id');
//       var path = dataId+"/enabled/";
//       $.ajax({
//          type:"GET",
//           url: path,
//           dataType: 'html',
//           success:function(code_html, statut){
//               alert('success');

//              return statut;

//           },
//       });

//       });

