//formation-form

$('#user_formation_form_driverLicence_0').click(() =>{
    $('.validityDateDriverLicence').removeClass('d-none');
});
$('#user_formation_form_driverLicence_1').click(() =>{
    $('.validityDateDriverLicence').addClass('d-none');
});
if ($('#user_formation_form_driverLicence_0').is(':checked')){
    $('.validityDateDriverLicence').removeClass('d-none');
}

$('#user_formation_form_coastalOptionBoatLicence_0').click(() =>{
    $('.validityDateCoastalOptionBoatLicence').removeClass('d-none');
});
$('#user_formation_form_coastalOptionBoatLicence_1').click(() =>{
    $('.validityDateCoastalOptionBoatLicence').addClass('d-none');
});
if ($('#user_formation_form_coastalOptionBoatLicence_0').is(':checked')){
    $('.validityDateCoastalOptionBoatLicence').removeClass('d-none');
}

$('#user_formation_form_inlandWatersOptionBoatLicense_0').click(() =>{
    $('.validityDateInlandWatersOptionBoatLicence').removeClass('d-none');
});
$('#user_formation_form_inlandWatersOptionBoatLicense_1').click(() =>{
    $('.validityDateInlandWatersOptionBoatLicence').addClass('d-none');
});
if ($('#user_formation_form_inlandWatersOptionBoatLicense_0').is(':checked')){
    $('.validityDateInlandWatersOptionBoatLicence').removeClass('d-none');
}

$('#user_formation_form_epiAntiChute_0').click(() =>{
    $('.expirationDateEpiAntiChute').removeClass('d-none');
});
$('#user_formation_form_epiAntiChute_1').click(() =>{
    $('.expirationDateEpiAntiChute').addClass('d-none');
});
if ($('#user_formation_form_epiAntiChute_0').is(':checked')){
    $('.expirationDateEpiAntiChute').removeClass('d-none');
}

$('#user_formation_form_ancrage_0').click(() =>{
    $('.expirationDateAncrage').removeClass('d-none');
});
$('#user_formation_form_ancrage_1').click(() =>{
    $('.expirationDateAncrage').addClass('d-none');
});
if ($('#user_formation_form_ancrage_0').is(':checked')){
    $('.expirationDateAncrage').removeClass('d-none');
}

$('#user_formation_form_suspension_0').click(() =>{
    $('.expirationDateSuspension').removeClass('d-none');
});
$('#user_formation_form_suspension_1').click(() =>{
    $('.expirationDateSuspension').addClass('d-none');
});
if ($('#user_formation_form_suspension_0').is(':checked')){
    $('.expirationDateSuspension').removeClass('d-none');
}

$('#user_formation_form_secouriste_0').click(() =>{
    $('.dateExpirationSecouriste').removeClass('d-none');
});
$('#user_formation_form_secouriste_1').click(() =>{
    $('.dateExpirationSecouriste').addClass('d-none');
});
if ($('#user_formation_form_secouriste_0').is(':checked')){
    $('.dateExpirationSecouriste').removeClass('d-none');
}

$('#user_formation_form_Afps_0').click(() =>{
    $('.dateExpirationAfps').removeClass('d-none');
});
$('#user_formation_form_Afps_1').click(() =>{
    $('.dateExpirationAfps').addClass('d-none');
});
if ($('#user_formation_form_Afps_0').is(':checked')){
    $('.dateExpirationAfps').removeClass('d-none');
}

// administrative-form

$('#user_administrative_form_identityCard_0').click(() =>{
    $('.validityDate').removeClass('d-none');
    $('.idCardNumber').removeClass('d-none');
});
$('#user_administrative_form_identityCard_1').click(() =>{
    $('.validityDate').addClass('d-none');
    $('.idCardNumber').addClass('d-none');
});
if ($('#user_administrative_form_identityCard_0').is(':checked')){
    $('.validityDate').removeClass('d-none');
    $('.idCardNumber').removeClass('d-none');
}

$('#user_administrative_form_passeport_0').click(() =>{
    $('.validityDatePasseport').removeClass('d-none');
    $('.idPasseportNumber').removeClass('d-none');
});
$('#user_administrative_form_passeport_1').click(() =>{
    $('.validityDatePasseport').addClass('d-none');
    $('.idPasseportNumber').addClass('d-none');
});
if ($('#user_administrative_form_passeport_0').is(':checked')){
    $('.validityDatePasseport').removeClass('d-none');
    $('.idPasseportNumber').removeClass('d-none');
}

// qualification-form

$('#user_qualification_form_f4t2n1_0').click(() =>{
    $('.dateCertifF4t2n1').removeClass('d-none');
});
$('#user_qualification_form_f4t2n1_1').click(() =>{
    $('.dateCertifF4t2n1').addClass('d-none');
});
if ($('#user_qualification_form_f4t2n1_0').is(':checked')){
    $('.dateCertifF4t2n1').removeClass('d-none');
}

$('#user_qualification_form_f4t2n2_0').click(() =>{
    $('.dateCertifF4t2n2').removeClass('d-none');
});
$('#user_qualification_form_f4t2n2_1').click(() =>{
    $('.dateCertifF4t2n2').addClass('d-none');
});
if ($('#user_qualification_form_f4t2n2_0').is(':checked')){
    $('.dateCertifF4t2n2').removeClass('d-none');
}

$('#user_qualification_form_distributeur_0').click(() =>{
    $('.societeDistributeur').removeClass('d-none');
});
$('#user_qualification_form_distributeur_1').click(() =>{
    $('.societeDistributeur').addClass('d-none');
});
if ($('#user_qualification_form_distributeur_0').is(':checked')){
    $('.societeDistributeur').removeClass('d-none');
}


$('#user_qualification_form_tabletir_9').click(() =>{
    $('.autre').removeClass('d-none');
});
$('#user_qualification_form_tabletir_8').click(() =>{
    $('.autre').addClass('d-none');
});
$('#user_qualification_form_tabletir_7').click(() =>{
    $('.autre').addClass('d-none');
});
$('#user_qualification_form_tabletir_6').click(() =>{
    $('.autre').addClass('d-none');
});
$('#user_qualification_form_tabletir_5').click(() =>{
    $('.autre').addClass('d-none');
});
$('#user_qualification_form_tabletir_4').click(() =>{
    $('.autre').addClass('d-none');
});
$('#user_qualification_form_tabletir_3').click(() =>{
    $('.autre').addClass('d-none');
});
$('#user_qualification_form_tabletir_2').click(() =>{
    $('.autre').addClass('d-none');
});
$('#user_qualification_form_tabletir_1').click(() =>{
    $('.autre').addClass('d-none');
});
$('#user_qualification_form_tabletir_0').click(() =>{
    $('.autre').addClass('d-none');
});
if ($('#user_qualification_form_tabletir_9').is(':checked')){
    $('.autre').removeClass('d-none');
}



// $('#user_qualification_form_tabletir').on('click', function() {
//     var value = $(this).val();
//     if(value == "Autre"){
//         $('.autre').removeClass('d-none');
//     }
//     else{
//         $('.autre').addClass('d-none');
//     }

//   });

//medical-form

$('#user_medical_form_restrictions_0').click(() =>{
    $('.nameRestrictions').removeClass('d-none');
});
$('#user_medical_form_restrictions_1').click(() =>{
    $('.nameRestrictions').addClass('d-none');
});
if ($('#user_medical_form_restrictions_0').is(':checked')){
    $('.nameRestrictions').removeClass('d-none');
}  

$('#user_medical_form_vaccinFievreJaune_0').click(() =>{
    $('.dateInjectionVaccinFievre').removeClass('d-none');
});
$('#user_medical_form_vaccinFievreJaune_1').click(() =>{
    $('.dateInjectionVaccinFievre').addClass('d-none');
});
if ($('#user_medical_form_vaccinFievreJaune_0').is(':checked')){
    $('.dateInjectionVaccinFievre').removeClass('d-none');
}  

$('#user_medical_form_vaccinHepatiteA_0').click(() =>{
    $('.dateInjectionVaccinHepatite').removeClass('d-none');
    $('.dateInjectionRappel').removeClass('d-none');
});
$('#user_medical_form_vaccinHepatiteA_1').click(() =>{
    $('.dateInjectionVaccinHepatite').addClass('d-none');
    $('.dateInjectionRappel').addClass('d-none');
});
if ($('#user_medical_form_vaccinHepatiteA_0').is(':checked')){
    $('.dateInjectionVaccinHepatite').removeClass('d-none');
    $('.dateInjectionRappel').removeClass('d-none');
}

$('#user_medical_form_vaccinMeningite_0').click(() =>{
    $('.dateInjectionVaccinMeningite').removeClass('d-none');
});
$('#user_medical_form_vaccinMeningite_1').click(() =>{
    $('.dateInjectionVaccinMeningite').addClass('d-none');
});
if ($('#user_medical_form_vaccinMeningite_0').is(':checked')){
    $('.dateInjectionVaccinMeningite').removeClass('d-none');
} 

//culture-form

$('#user_culture_form_contraintesSecurite_0').click(() =>{
    $('.exempleSecurite').removeClass('d-none');
});
$('#user_culture_form_contraintesSecurite_1').click(() =>{
    $('.exempleSecurite').addClass('d-none');
});
if ($('#user_culture_form_contraintesSecurite_0').is(':checked')){
    $('.exempleSecurite').removeClass('d-none');
} 

//caces-form

$('#user_caces_form_caces9_0').click(() =>{
    $('.dateCaces9').removeClass('d-none');
});
$('#user_caces_form_caces9_1').click(() =>{
    $('.dateCaces9').addClass('d-none');
});
if ($('#user_caces_form_caces9_0').is(':checked')){
    $('.dateCaces9').removeClass('d-none');
}

$('#user_caces_form_caces10_0').click(() =>{
    $('.dateCaces10').removeClass('d-none');
});
$('#user_caces_form_caces10_1').click(() =>{
    $('.dateCaces10').addClass('d-none');
});
if ($('#user_caces_form_caces10_0').is(':checked')){
    $('.dateCaces10').removeClass('d-none');
} 

$('#user_caces_form_cacesNacelle1A_0').click(() =>{
    $('.dateCacesNacelle1A').removeClass('d-none');
});
$('#user_caces_form_cacesNacelle1A_1').click(() =>{
    $('.dateCacesNacelle1A').addClass('d-none');
});
if ($('#user_caces_form_cacesNacelle1A_0').is(':checked')){
    $('.dateCacesNacelle1A').removeClass('d-none');
} 

$('#user_caces_form_cacesNacelle1B_0').click(() =>{
    $('.dateCacesNacelle1B').removeClass('d-none');
});
$('#user_caces_form_cacesNacelle1B_1').click(() =>{
    $('.dateCacesNacelle1B').addClass('d-none');
});
if ($('#user_caces_form_cacesNacelle1B_0').is(':checked')){
    $('.dateCacesNacelle1B').removeClass('d-none');
} 

$('#user_caces_form_cacesNacelle3A_0').click(() =>{
    $('.dateCacesNacelle3A').removeClass('d-none');
});
$('#user_caces_form_cacesNacelle3A_1').click(() =>{
    $('.dateCacesNacelle3A').addClass('d-none');
});
if ($('#user_caces_form_cacesNacelle3A_0').is(':checked')){
    $('.dateCacesNacelle3A').removeClass('d-none');
} 

$('#user_caces_form_cacesNacelle3B_0').click(() =>{
    $('.dateCacesNacelle3B').removeClass('d-none');
});
$('#user_caces_form_cacesNacelle3B_1').click(() =>{
    $('.dateCacesNacelle3B').addClass('d-none');
});
if ($('#user_caces_form_cacesNacelle3B_0').is(':checked')){
    $('.dateCacesNacelle3B').removeClass('d-none');
} 

$('#user_caces_form_CarCaces1_0').click(() =>{
    $('.dateCarCaces1').removeClass('d-none');
});
$('#user_caces_form_CarCaces1_1').click(() =>{
    $('.dateCarCaces1').addClass('d-none');
});
if ($('#user_caces_form_CarCaces1_0').is(':checked')){
    $('.dateCarCaces1').removeClass('d-none');
} 

$('#user_caces_form_CarCaces1B_0').click(() =>{
    $('.dateCarCaces1B').removeClass('d-none');
});
$('#user_caces_form_CarCaces1B_1').click(() =>{
    $('.dateCarCaces1B').addClass('d-none');
});
if ($('#user_caces_form_CarCaces1B_0').is(':checked')){
    $('.dateCarCaces1B').removeClass('d-none');
} 

$('#user_caces_form_carCaces2_0').click(() =>{
    $('.dateCarCaces2').removeClass('d-none');
});
$('#user_caces_form_carCaces2_1').click(() =>{
    $('.dateCarCaces2').addClass('d-none');
});
if ($('#user_caces_form_carCaces2_0').is(':checked')){
    $('.dateCarCaces2').removeClass('d-none');
} 

$('#user_caces_form_carCaces2B_0').click(() =>{
    $('.dateCarCaces2B').removeClass('d-none');
});
$('#user_caces_form_carCaces2B_1').click(() =>{
    $('.dateCarCaces2B').addClass('d-none');
});
if ($('#user_caces_form_carCaces2B_0').is(':checked')){
    $('.dateCarCaces2B').removeClass('d-none');
} 

$('#user_caces_form_carCaces3_0').click(() =>{
    $('.dateCarCaces3').removeClass('d-none');
});
$('#user_caces_form_carCaces3_1').click(() =>{
    $('.dateCarCaces3').addClass('d-none');
});
if ($('#user_caces_form_carCaces3_0').is(':checked')){
    $('.dateCarCaces3').removeClass('d-none');
} 

$('#user_caces_form_carCaces4_0').click(() =>{
    $('.dateCarCaces4').removeClass('d-none');
});
$('#user_caces_form_carCaces4_1').click(() =>{
    $('.dateCarCaces4').addClass('d-none');
});
if ($('#user_caces_form_carCaces4_0').is(':checked')){
    $('.dateCarCaces4').removeClass('d-none');
}

$('#user_caces_form_carCaces5_0').click(() =>{
    $('.dateCarCaces5').removeClass('d-none');
});
$('#user_caces_form_carCaces5_1').click(() =>{
    $('.dateCarCaces5').addClass('d-none');
});
if ($('#user_caces_form_carCaces5_0').is(':checked')){
    $('.dateCarCaces5').removeClass('d-none');
}

$('#user_caces_form_carCaces6_0').click(() =>{
    $('.dateCarCaces6').removeClass('d-none');
});
$('#user_caces_form_carCaces6_1').click(() =>{
    $('.dateCarCaces6').addClass('d-none');
});
if ($('#user_caces_form_carCaces6_0').is(':checked')){
    $('.dateCarCaces6').removeClass('d-none');
}


// $('.btn-link').click(function(event) {
//     var row = $(event.target).parent().parent().parent()

//     if ($(row).find('.icon-plus').hasClass('d-none')){
//         $(row).find('.icon-moins').addClass('d-none');
//         $('.icon-plus').removeClass('d-none');
//     }else{
//         $(row).find('.icon-moins').removeClass('d-none');
//         $(row).find('.icon-plus').addClass('d-none');
//     }
// });

$('.collapse').on('shown.bs.collapse', function(){
    $(this).parent().find(".icon-plus").removeClass("icon-plus").addClass("icon-moins");
    }).on('hidden.bs.collapse', function(){
    $(this).parent().find(".icon-moins").removeClass("icon-moins").addClass("icon-plus");
});


$('.drawer').click(function(){
    if($('.drawer-title').hasClass('d-none')){
        $('.drawer-title').removeClass('d-none');
        $('.row-picto ').removeClass('justify-content-center');
    }
    else{
        $('.drawer-title').addClass('d-none');
        $('.row-picto ').addClass('justify-content-center');
    }
});

$('input[id="user_administrative_form_identityRectoFile_file"]').change(function(e){
    $('.identityRectoFile-info').addClass('d-none');
    var fileName =e.target.files[0].name;
    $('.identityRectoFile').empty().append(fileName);

});

$('input[id="user_administrative_form_identityVersoFile_file"]').change(function(e){
    $('.identityVersoFile-info').addClass('d-none');
    var fileName =e.target.files[0].name;
    $('.identityVersoFile').empty().append(fileName);

});

$('input[id="user_administrative_form_passeportRectoFile_file"]').change(function(e){
    $('.passeportRectoFile-info').addClass('d-none');
    var fileName =e.target.files[0].name;
    $('.passeportRectoFile').empty().append(fileName);

});

$('input[id="user_qualification_form_scanCertifFile_file"]').change(function(e){
    $('.scanCertifFile-info').addClass('d-none');
    var fileName =e.target.files[0].name;
    $('.scanCertifFile').empty().append(fileName);

});

$('#user_formation_form_caces_0').click(function(){
    $('.info-caces').removeClass('d-none')
});
$('#user_formation_form_caces_1').click(function(){
    $('.info-caces').addClass('d-none')
});


 $(document).ready(function() {
   
$('.form-control-date').datepicker({
    dateFormat: "d/m/yy",
    monthNames: [ "Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre" ],
    dayNamesMin : [ "Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"  ]
  });
 


    // $navigateur = 'User-agent header sent: ' + navigator.userAgent;
    // if($navigateur.toLowerCase().match(/(macintosh)/)){


    //     $('input[type="date"]').datepicker({
    //         dateFormat: "yy-mm-dd"
    //       });

    // }
   
    });